import { useRouter } from 'next/router'
import { useEffect } from 'react'
import ErrorLayout from '@/layouts/error'
import useTranslation from '@/hooks/translation'
import { reportMessage } from '@/utils/error'

function shouldIgnore(path) {
  return (
    path.startsWith('/checkout') &&
    (path.endsWith('/payment') || path.endsWith('/travelers'))
  )
}

function NotFound() {
  const router = useRouter()
  const { t } = useTranslation()

  useEffect(() => {
    const path = router.asPath.split('?')[0]

    // exlude errors on path : /checkout/..../travelers
    if (!shouldIgnore(path)) {
      reportMessage(`404 on path: ${path}`, 'info')
    }
  }, [router])

  return (
    <ErrorLayout
      title="404"
      subtitle={t('pages.404.subtitle')}
      message={t('pages.404.message')}
      buttonText={t('pages.404.buttonText')}
      buttonHref="/"
    />
  )
}

NotFound.skipLayout = true

export default NotFound
